import React, { useEffect } from "react";
import { useState } from "react";
import BillingTableOne from "../components/BillingTableOne";
import GetCustomer from "../components/GetCustomer";
import Copyrights from "../components/Copyrights";
import RecentTransiction from "../components/Billing/BillingAccordion";
import BlankTable from "../components/Billing/BlankTable";
import SearchableDropdown from "../components/ItemSettings/SearchableDropdown";
import { BASE_URL, ERROR_MESSAGE } from "../Url/constants";
import { CloseOutlined } from "@ant-design/icons";
import "./Billing.css";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import { sendRequest } from "../ApiRequest";
import BillingPreview from "./BillingPreview";
import BillingTemplateOne from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateOne";
import BillingTemplateTwo from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateTwo";
import BillingTemplateThree from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateThree";
import BillingTemplateFour from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateFour";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import PrintableInvoice from "../components/PrintableInvoice";

function Billing() {
  const [activeTab, setActiveTab] = useState(1);
  const [billingForms, setBillingForms] = useState([]);
  const [error] = useState(null);
  const [billNumber, setBillNumber] = useState(1);
  const [updateTransactions, setUpdateTransactions] = useState(false);
  const [BillingPreviewData, setBillingPreviewData] = useState([]);
  const [ShowBillingPreview, setShowBillingPreview] = useState(false);
  const [manualDiscount, setManualDiscount] = useState();
  const [currentFromIndex, setcurrentFormIndex] = useState();
  const [isPreviewEnabled, setIspreviewEnabled] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [billSettings, setBillSettings] = useState([]);
  const [enableGST, setEnableGST] = useState(false);
  const { t } = useTranslation();
  const [hiddenForm, setHiddenForm] = useState(0);
  const [autoFocusInput, setAutoFocusInput] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(" ");

  const handleTabClick = (tabIndex) => {
    if (tabIndex === activeTab) {
      setActiveTab(1);
      setIsDisabled(false);
    } else {
      setActiveTab(tabIndex);
    }
    setAutoFocusInput(false);
  };

  const [discountType, setDiscountType] = useState("fixed");
  const handleApplyDiscount = (manualDiscount, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = updatedForms[formIndex];
      manualDiscount = Number(manualDiscount);
      if (isNaN(manualDiscount) || manualDiscount <= 0) {
        updatedForms[formIndex] = {
          ...form,
          totalDiscountAmount: form.totalDiscountAmountCopy,
          totalAmount: form.totalAmountCopy,
        };
        setErrorMessage("Please enter a valid discount.");
        return updatedForms;
      }

      let discountValue = 0;

      if (discountType === "percentage") {
        discountValue = (form.totalAmountCopy * manualDiscount) / 100;
      } else {
        discountValue = manualDiscount;
      }

      if (discountValue > form.totalAmountCopy) {
        setErrorMessage("Discount cannot exceed the total amount");
        return updatedForms;
      }

      const totalDiscount = discountValue + Number(form.couponDiscount || 0);
      const totalAmountAfterDiscount = Math.max(
        0,
        form.totalAmountCopy - totalDiscount
      );

      updatedForms[formIndex] = {
        ...form,
        totalDiscountAmount: totalDiscount + form.totalDiscountAmountCopy,
        totalAmount: totalAmountAfterDiscount,
      };
      setErrorMessage("");
      return updatedForms;
    });
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setManualDiscount(value);
    } else {
      setErrorMessage();
    }
  };

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
  };

  const getBillingSettings = () => {
    fetchInitialBillNumber();
    getDefaultTemaplate();
    setIspreviewEnabled(false);
    fetchSettings();
    fetchGSTSetting();
  };

  useEffect(() => {
    const sendToAll = isCheckboxEnabled("send_to_all");
    if (sendToAll) {
      setBillingForms((prevForms) =>
        prevForms.map((form) => ({
          ...form,
          sendSMS: sendToAll,
          sendWhatsapp: sendToAll,
          sendEmail: sendToAll,
        }))
      );
    }
  }, [billSettings, enableGST]);

  const fetchGSTSetting = async () => {
    const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
    try {
      const response = await sendRequest("GET", url);
      if (response.status === 200) {
        const gstSetting = response.data;
        if (gstSetting) {
          setEnableGST(gstSetting.status);
        }
      } else {
        console.error("Error fetching GST setting!", error);
      }
    } catch (error) {
      console.error("Error fetching GST setting!", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/billing_settings`
      );
      setBillSettings(response.data.data);
      const previewSetting = response.data.data.find(
        (setting) => setting.name === "billing_preview"
      );
      if (previewSetting && previewSetting.billing_setting_config?.status) {
        setIspreviewEnabled(true);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const isCheckboxEnabled = (optionKey) => {
    const option = billSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const getDefaultTemaplate = async () => {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/template/templates/get_default_template`
    );
    if (response.status === 200) {
      setTemplateId(response?.data?.data?.id);
    }
  };

  const fetchInitialBillNumber = async () => {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/billings/last_invoice_bill_number`
    );
    if (response.status === 200) {
      setBillNumber(response.data);
    }
  };

  const handlePrintInvoice = async (
    formIndex,
    template = templateId ? templateId : 1
  ) => {
    debugger
    const formData = billingForms[formIndex];
    if (formData) {
      const uniqueItems = [];
      const itemIds = new Set();

      formData.selectedItems.forEach((item) => {
        if (!item.quantityUpdated) {
          item.quantity = 1;
        }
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item.id,
            quantity: item.quantity,
            item_total_discount: item?.discount_amount?.toFixed(2),
            item_total_tax: item?.tax_amount?.toFixed(2),
            item_total_amount: item?.sale_amount?.toFixed(2),
            stock_diffrence_discount: item.diffrence_discount
              ? item.diffrence_discount.toFixed(2)
              : 0,
            stock_ids: item.stock_ids,
          });
        }
      });
      const subTotal = formData.couponDiscount
        ? (formData.totalAmount - Number(formData.couponDiscount)).toFixed(2)
        : formData.totalAmount.toFixed(2);
      const totalTax = formData?.totalTaxAmount?.toFixed(2);
      const totalGst = enableGST ? (Number(subTotal) * 0.18).toFixed(2) : 0;
      const totalDiscount = formData?.couponDiscount
        ? (
            Number(formData?.couponDiscount) + formData?.totalDiscountAmount
          ).toFixed(2)
        : formData?.totalDiscountAmount?.toFixed(2);
      const billing_params = {
        bill: {
          customer_id: formData.selectedCustomer
            ? formData.selectedCustomer.id
            : null,
          total_amount: (
            Number(subTotal) +
            Number(totalTax) +
            (enableGST ? Number(totalGst) : 0)
          ).toFixed(2),
          sub_total: subTotal,
          total_discount: totalDiscount,
          total_tax: totalTax,
          total_gst: totalGst,
          is_gst_enable: enableGST,
          is_igst_enable: isCheckboxEnabled("inter_state_GST"),
          bill_number: billNumber + 1,
          serial_number: 1234578,
          status: 1,
          bill_type: 0,
          send_by_sms: formData.sendSMS,
          send_by_whatsapp: formData.sendWhatsapp,
          send_by_email: formData.sendEmail,
          bill_items_attributes: uniqueItems,
          payment_mode: formData.paymentMode
            ? parseInt(formData.paymentMode)
            : 0,
          print_qr: formData.qrCode,
          print_gst_no: formData.gstNumber,
          print_logo: formData.printLogo,
          template_id: template,
          coupon_id: formData.coupon_id ? formData.coupon_id : "",
        },
        is_sale: true,
      };
      await sendRequest("POST", `${BASE_URL}/v1/billings/bills`, billing_params)
        .then((response) => {
          if (response.status === 200) {
            setBillNumber(billNumber + 1);
            setUpdateTransactions(true);
            handleTabClose(formIndex);
            setIsDisabled(false);
            if (ShowBillingPreview) {
              setShowBillingPreview(false);
            }
            // handleBillPrinting(template, billing_params, (Number(formData.couponDiscount) + formData.totalDiscountAmount).toFixed(2))
            if (!isPreviewEnabled) {
              handleBillPrinting(response.data.data);
            }
          }
        })
        .catch((error) => {
          const msg = ERROR_MESSAGE("invoice");
          ErrorNotification(msg);
          setIsDisabled(false);
        });
    }
    else {
      setIsDisabled(false);
    }
  };

  const handleBillPrinting = async (invoiceData) => {
    try {
      const configResponse = await sendRequest(
        "GET",
        `${BASE_URL}/v1/template/templates/get_default_receipt`
      );
      const templateId = configResponse.data.data.id;
      const templateConfig = configResponse.data.data.template_options;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Bill</title>
          </head>
          <body>
            <div id="printable-bill"></div>
          </body>
        </html>
      `);
      printWindow.document.close();

      ReactDOM.render(
        <PrintableInvoice
          invoiceData={invoiceData}
          templateId={templateId}
          templateConfig={templateConfig}
        />,
        printWindow.document.getElementById("printable-bill")
      );

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    } catch (error) {
      ErrorNotification("Failed to print bill:", error);
    }
  };

  const getBillingTemplate = (template, formData, discount) => {
    switch (template) {
      case 1:
        return (
          <BillingTemplateOne
            template={1}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      case 2:
        return (
          <BillingTemplateTwo
            template={2}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      case 3:
        return (
          <BillingTemplateThree
            template={3}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      case 4:
        return (
          <BillingTemplateFour
            template={4}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      default:
        return null;
    }
  };

  const addNewForm = () => {
    if (billingForms.length - hiddenForm < 5) {
      const sendToAll = isCheckboxEnabled("send_to_all");
      const newForm = {
        selectedCustomer: null,
        selectedItems: [],
        showBlankForm: true,
        totalAmount: 0,
        totalTax: 0,
        totalGst: 0,
        totalDiscount: 0,
        sendSMS: sendToAll,
        sendWhatsapp: sendToAll,
        sendEmail: sendToAll,
        printLogo: false,
        gstNumber: false,
        qrCode: false,
        paymentMode: null,
        coupon_id: null,
        totalAmountCopy: 0,
        actualPrice: 0,
        totalDiscountAmountCopy: 0,
      };
      setBillingForms([...billingForms, newForm]);
      setActiveTab(billingForms.length + 2);
      setAutoFocusInput(true);
      setIsDisabled(false);
      getBillingSettings();
    }
  };

  const handleSelectOption = (option, formIndex) => {
    const updatedForms = [...billingForms];
    const selectedItems = [...updatedForms[formIndex].selectedItems, option];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedItems: selectedItems,
      showBlankForm: false,
    };
    setBillingForms(updatedForms);
  };

  const handleUpdateItems = (updatedItems, formIndex) => {
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedItems: updatedItems,
    };
    setBillingForms(updatedForms);
  };

  const updateTotalPrice = (price, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = updatedForms[formIndex];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        totalAmount: price,
        totalAmountCopy: price,
      };
      return updatedForms;
    });
  };

  const updateActualPrice = (price, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        actualPrice: price,
      };
      return updatedForms;
    });
  };

  const updateTotalTaxPrice = (taxPrice, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        totalTaxAmount: taxPrice,
      };
      return updatedForms;
    });
  };

  const updateTotalDiscount = (discountAmount, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        totalDiscountAmount: discountAmount,
        totalDiscountAmountCopy: discountAmount,
      };
      return updatedForms;
    });
  };

  // const handleCheckboxChange = (event, formIndex) => {
  //   const { id, checked } = event.target;
  //   const checkboxType = id.split("-")[0];

  //   setBillingForms((prevForms) => {
  //     const updatedForms = [...prevForms];
  //     updatedForms[formIndex] = {
  //       ...updatedForms[formIndex],
  //       [`${checkboxType}`]: checked,
  //     };
  //     return updatedForms;
  //   });
  // };

  const addCouponDiscount = (value, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      let couponDiscount;
      if (
        isNaN(updatedForms[formIndex].totalAmount) ||
        updatedForms[formIndex].totalAmount <= 0
      ) {
        ErrorNotification("Total amount is invalid.");
        return prevForms;
      }
      if (value.coupon_type === "percent") {
        couponDiscount = (
          (updatedForms[formIndex].totalAmount * value?.value) /
          100
        ).toFixed(2);
      } else if (value.coupon_type === "fixed") {
        couponDiscount = value?.value;
      }

      couponDiscount = parseFloat(couponDiscount);

      if (isNaN(couponDiscount)) {
        ErrorNotification("Invalid coupon discount value.");
        return prevForms;
      }

      if (couponDiscount > updatedForms[formIndex].totalAmount) {
        ErrorNotification(
          "Can't apply this coupon because the discount exceeds the total amount."
        );
        return prevForms;
      }

      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        couponDiscount: couponDiscount,
        coupon_id: value.id,
      };

      return updatedForms;
    });
  };

  const handleCouponDiscount = async (code, formIndex) => {
    const couponCode = code;
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/setting/coupons/show_by_code/${couponCode}`
    );
    if (response.status === 200) {
      const coupon = response.data;
      addCouponDiscount(coupon, formIndex);
    }
  };

  const handleCheckboxChange = (event, formIndex) => {
    const { id, checked } = event.target;
    const checkboxType = id.split("-")[0];

    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        [`${checkboxType}`]: checked,
      };
      return updatedForms;
    });
  };

  const handlePaymentModeChange = (event, formIndex) => {
    const { value } = event.target;
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        paymentMode: value,
      };
      return updatedForms;
    });
  };

  const handleCustomerSelect = (customer, formIndex) => {
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedCustomer: customer,
    };
    setBillingForms(updatedForms);
  };

  const handleTabClose = (formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        hidden: true,
      };
      const hiddenCount = updatedForms.filter((form) => form.hidden).length;
      setHiddenForm(hiddenCount);
      return updatedForms;
    });

    if (activeTab === formIndex + 2) {
      setActiveTab(1);
    } else {
      const newActiveTabIndex =
        activeTab > formIndex + 2 ? activeTab - 1 : activeTab;
      setActiveTab(newActiveTabIndex);
    }
  };

  const formatBillNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  const handleBillingPreview = (formIndex) => {
    setBillingPreviewData(billingForms[formIndex]);
    setcurrentFormIndex(formIndex);
    setShowBillingPreview(true);
  };

  const handleCancel = () => {
    setBillingPreviewData(null);
    setShowBillingPreview(false);
  };

  const handleUpdateTransactions = (status) => {
    setUpdateTransactions(status);
  };

  const handleNewBillingClick = () => {
    setAutoFocusInput(true);
  };

  const handleClick = (index) => {
    setIsDisabled(true);
    handlePrintInvoice(index);
  };


  return (
    <>
      <div className="tab-wrapper">
        <div className="billing-tabs">
          <div
            className={activeTab === 1 ? "btab active-tab" : "btab"}
            onClick={() => handleTabClick(1)}
          >
            {t("Billing")}
          </div>
          {billingForms.map(
            (form, index) =>
              !form.hidden && (
                <div
                  key={index}
                  className={
                    activeTab === index + 2 ? "btab active-tab" : "btab"
                  }
                  onClick={() => handleTabClick(index + 2)}
                >
                  {t("New Billing")}
                  <span
                    className="close-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTabClose(index);
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </div>
              )
          )}
          <div className="add-form-btn" onClick={addNewForm}>
            <button className="btab">+</button>
          </div>
        </div>
        <div
          className={
            activeTab === 1 ? "tab-content active-content" : "tab-content"
          }
        >
          {/* Content for  main Billing tab */}
          <RecentTransiction
            updateTransactions={updateTransactions}
            setUpdateTransactions={handleUpdateTransactions}
            billSettings={billSettings}
          />
        </div>
        {billingForms.map((form, index) => (
          <div
            key={index}
            className={
              activeTab === index + 2
                ? "tab-content active-content"
                : "tab-content"
            }
          >
            <div className="billing-container">
              <div className="row">
                <div className="col-md-8">
                  <div className="billing-left">
                    <div className="billing-header">
                      <div className="bill-number">
                        <h6>{t("Fill required details and Create Billing")}</h6>
                      </div>
                      {/* <div className="form-header">
                        <strong>Bill Number:</strong> {formatBillNumber(billNumber + 1)}
                      </div> */}
                      {isCheckboxEnabled("invoice_no") && (
                        <div className="form-header">
                          <strong>{t("Bill Number")}:</strong>{" "}
                          {formatBillNumber(billNumber + 1)}
                        </div>
                      )}
                    </div>
                    <SearchableDropdown
                      options={[]}
                      label="item_name"
                      id="id"
                      handleChange={(option) =>
                        handleSelectOption(option, index)
                      }
                      autoFocus={autoFocusInput && activeTab === index + 2}
                    />
                    {form.showBlankForm ? (
                      <BlankTable />
                    ) : (
                      // <BillingTableOne selectedItems={form.selectedItems} updateTotalPrice={updateTotalPrice}/>
                      <BillingTableOne
                        selectedItems={form.selectedItems}
                        updateActualPrice={(price) =>
                          updateActualPrice(price, index)
                        }
                        updateTotalPrice={(price) =>
                          updateTotalPrice(price, index)
                        }
                        updateTotalTaxPrice={(taxPrice) =>
                          updateTotalTaxPrice(taxPrice, index)
                        }
                        updateTotalDiscount={(discountAmount) =>
                          updateTotalDiscount(discountAmount, index)
                        }
                        updateItems={(updatedItems) =>
                          handleUpdateItems(updatedItems, index)
                        }
                        billSettings={billSettings}
                      />
                    )}
                    <div className="billing-checks">
                      <div className="row">
                        <div className="col-md-10">
                          <form>
                            {isCheckboxEnabled("message") && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`sendSMS-${index}`}
                                  checked={form.sendSMS}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`sendSMS-${index}`}
                                >
                                  {t("Send by SMS")}
                                </label>
                              </div>
                            )}

                            {isCheckboxEnabled("whats_app") && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`sendWhatsapp-${index}`}
                                  checked={form.sendWhatsapp}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`sendWhatsapp-${index}`}
                                >
                                  {t("Send by WhatsApp")}
                                </label>
                              </div>
                            )}

                            {isCheckboxEnabled("send_by_email") && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`sendEmail-${index}`}
                                  checked={form.sendEmail}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`sendEmail-${index}`}
                                >
                                  {t("Send by Email")}
                                </label>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="mt-20">
                      {/* <button className="btn btn-primary">Print Invoice</button> */}
                      {isPreviewEnabled ? (
                        <button
                          onClick={() => handleBillingPreview(index)}
                          className="btn btn-primary"
                          disabled={form?.selectedItems?.length === 0}
                        >
                          {t("Preview")}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleClick(index)}
                          className="btn btn-primary"
                          disabled={isDisabled}
                        >
                          {t("Print Invoice")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content mt0 billing-right">
                    <h6>
                      <strong>{t("Customer Details")}</strong>
                    </h6>
                    <GetCustomer
                      onCustomerSelect={(customer) =>
                        handleCustomerSelect(customer, index)
                      }
                    />
                    <div className="amount-details">
                      <ul>
                        <li>
                          {t("Sub Total")}
                          <span>
                            {form.actualPrice
                              ? form.actualPrice.toFixed(2)
                              : "0.00"}
                          </span>
                        </li>

                        {isCheckboxEnabled("item_wise_tax") && (
                          <>
                            <li>
                              {isCheckboxEnabled("inter_state_GST") &&
                              enableGST ? (
                                <>
                                  {t("IGST")} (18%)
                                  <span>
                                    {form.totalAmount
                                      ? (form.totalAmount * 0.18).toFixed(2)
                                      : "0.00"}
                                  </span>
                                </>
                              ) : enableGST ? (
                                <>
                                  {t("CGST")} (9%) + SGST (9%)
                                  <span>
                                    {form.totalAmount
                                      ? (form.totalAmount * 0.09).toFixed(2)
                                      : "0.00"}{" "}
                                    +{" "}
                                    {form.totalAmount
                                      ? (form.totalAmount * 0.09).toFixed(2)
                                      : "0.00"}
                                  </span>
                                </>
                              ) : null}
                            </li>
                            <li>
                              {t("Tax")}
                              <span>
                                {form.totalTaxAmount
                                  ? form.totalTaxAmount.toFixed(2)
                                  : "0.00"}
                              </span>
                            </li>
                          </>
                        )}

                        {(isCheckboxEnabled("auto_discount") ||
                          isCheckboxEnabled("manual_discount")) && (
                          <li>
                            {t("Total Discount")}
                            <span>
                              {form.totalDiscountAmount
                                ? form.couponDiscount
                                  ? `- ${(
                                      Number(form.couponDiscount) +
                                      form.totalDiscountAmount
                                    ).toFixed(2)}`
                                  : `- ${form.totalDiscountAmount.toFixed(2)}`
                                : "0.00"}
                              {/* {form.couponDiscount ? ( Number(form.couponDiscount) + form.totalDiscountAmount).toFixed(2) : form.totalDiscountAmount} */}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* <div className="total-amount"> */}
                    {/* <h3>Total Amount <span>000.00</span></h3> */}
                    {/* <h3>Total Amount: <span>{
                        form.totalAmount ? (form.totalAmount + form.totalTaxAmount).toFixed(2) : "000.00"}</span></h3>
                    </div> */}

                    {/* <div className="total-amount">
                      <div className="total-bill-amount"><span className="bill-label">Total Amount: </span><span className="amount-total-value">{
                        form.totalAmount
                          ? isCheckboxEnabled("round_of_total")
                            ? Math.round(form.totalAmount + form.totalTaxAmount)
                            : (form.totalAmount + form.totalTaxAmount).toFixed(2)
                          : "000.00"
                      }</span></div>
                    </div> */}

                    <div className="total-amount">
                      <div className="total-bill-amount">
                        <span className="bill-label">
                          {t("Total Amount")}:{" "}
                        </span>
                        <span className="amount-total-value">
                          {form.totalAmount
                            ? (() => {
                                let taxAmount = 0;
                                if (
                                  isCheckboxEnabled("item_wise_tax") &&
                                  enableGST
                                ) {
                                  if (isCheckboxEnabled("inter_state_GST")) {
                                    taxAmount = form.totalAmount * 0.18; // IGST calculation
                                  } else {
                                    taxAmount = form.totalAmount * 0.09 * 2; // CGST + SGST calculation
                                  }
                                }
                                const total = form.couponDiscount
                                  ? form.totalAmount -
                                    Number(form.couponDiscount) +
                                    form.totalTaxAmount +
                                    taxAmount
                                  : form.totalAmount +
                                    form.totalTaxAmount +
                                    taxAmount;
                                return isCheckboxEnabled("round_of_total")
                                  ? Math.round(total)
                                  : total.toFixed(2);
                              })()
                            : "000.00"}
                        </span>
                      </div>
                    </div>

                    {isCheckboxEnabled("payment_mode") && (
                      <div className="payment-mode">
                        <h6>
                          <strong>{t("Payment Mode")}</strong>
                        </h6>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={form.paymentMode || ""}
                          onChange={(e) => handlePaymentModeChange(e, index)}
                        >
                          <option value="" disabled hidden>
                            {t("Select Option")}
                          </option>
                          <option value={0}>{t("cash")}</option>
                          <option value={1}>{t("online")}</option>
                          <option value={2}>{t("card")}</option>
                        </select>
                      </div>
                    )}
                    {isCheckboxEnabled("seller_details") && (
                      <div>
                        <h6>
                          <strong>{t("Seller Details")}</strong>
                        </h6>
                        <form>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={`qrCode-${index}`}
                              checked={form.qrCode}
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`qrCode${index}`}
                            >
                              {t("Print QR Code")}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={`gstNumber-${index}`}
                              checked={form.gstNumber}
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`gstNumber${index}`}
                            >
                              {t("Print GST Number")}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={`printLogo-${index}`}
                              checked={form.printLogo}
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`printLogo${index}`}
                            >
                              {t("Print Logo")}
                            </label>
                          </div>
                        </form>
                      </div>
                    )}
                    <div className="billing-coupon-group">
                      <h6 className="billing-discount-coupon-label">
                        <strong>{t("Discount Coupon")}</strong>
                      </h6>

                      <input
                        type="text"
                        className="form-control"
                        name="coupon"
                        placeholder="Please Select Coupon Code"
                        onChange={(e) =>
                          setBillingForms((prevForms) => {
                            const updatedForms = [...prevForms];

                            updatedForms[index] = {
                              ...updatedForms[index],

                              couponCode: e.target.value,
                            };

                            return updatedForms;
                          })
                        }
                      />
                    </div>
                    {isCheckboxEnabled("manual_discount") && (
                      <div className="billing-discount-group">
                        <h6 className="billing-discount-label">
                          <strong>{t("Discount")}</strong>
                        </h6>
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                        <div className="discount-input-group">
                        <select
                          value={discountType}
                          onChange={handleDiscountTypeChange}
                          style={{ marginRight: "10px", borderRadius: "4px"}}
                        >
                          <option value="fixed">{t("Fixed")}</option>
                          <option value="percentage">{t("Percentage")}</option>
                        </select>
                        <input
                          type="number"
                          value={manualDiscount}
                          style={{width: "56%", borderRadius: "4px"}}
                          placeholder="Enter The Amount"
                          onChange={(e) => {
                            setManualDiscount(e.target.value);
                            handleApplyDiscount(e.target.value, index);
                          }}
                        />
                      </div>
                      </div>
                    )}
                    <div className="billing-apply-coupon-main-btn">
                      <button
                        className="billling-coupon-apply-btn"
                        onClick={() => {
                          handleCouponDiscount(form.couponCode, index);
                        }}
                      >
                        {t("Apply")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <NotificationContainer />
        {ShowBillingPreview && (
          <BillingPreview
            formData={BillingPreviewData}
            currentFrom={currentFromIndex}
            billNumber={billNumber}
            igst_enable={isCheckboxEnabled("inter_state_GST")}
            gst_enable={enableGST}
            generateBill={(currentFrom, template) =>
              handlePrintInvoice(currentFrom, template)
            }
            onCancel={handleCancel}
          />
        )}
      </div>
      <Copyrights />
    </>
  );
}

export default Billing;
