import React, { useState, useEffect } from 'react';
import { sendRequest } from '../../../ApiRequest';
import { faSearch, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getToken } from '../../SecureStorage/Token';
import { BASE_URL } from '../../../Url/constants';
import './transactions.css'
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../dashboarddheader/Dheader';

const TransactionList = () => {
  const navigate = useNavigate();
  const token = getToken();
  const [purchaseReports, setPurchaseReports] = useState([]);
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [totalPrice, setTotalPrice] = useState(0);
  const [cash, setCash] = useState(0);
  const [online, setOnline] = useState(0);
  const [unpaid, setUnpaid] = useState(0);

  const handleTemplate = () => {
    navigate('/purchase');
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const clearInput = () => {
    setInputValue('');
  };

  const fetchPurchaseReports = async () => {
    try {
      const response = await sendRequest('get', `${BASE_URL}/v1/report/purchase_reports`);
      setPurchaseReports(response.data);
    } catch (error) {
      setError('No transaction found');
    }
  };

  useEffect(() => {
    fetchPurchaseReports();
  }, [token]);

  const handleDelete = async (id) => {
    try {
      const response = await sendRequest('delete', `${BASE_URL}/v1/report/purchase_reports/${id}`);
      fetchPurchaseReports();
    } catch (error) {
      console.error('Error deleting transaction:', error.message);
    }
  };

  useEffect(() => {
    if (purchaseReports.length > 0) {
      let cashTotal = 0;
      let onlineTotal = 0;
      let unpaidTotal = 0;
      let total = 0;

      purchaseReports.forEach(transaction => {
        switch (transaction.payment_type) {
          case 'Cash':
            cashTotal += transaction.price;
            break;
          case 'Online':
            onlineTotal += transaction.price;
            break;
          case 'Unpaid':
            unpaidTotal += transaction.price;
            break;
          default:
            break;
        }
        total += transaction.price;
      });

      setCash(cashTotal);
      setOnline(onlineTotal);
      setUnpaid(unpaidTotal);
      setTotalPrice(total);
    }
  }, [purchaseReports]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(purchaseReports.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = purchaseReports.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="transaction-list">
      <div>
        <DashboardHeader totalPrice={totalPrice} cash={cash} online={online} unpaid={unpaid} />
      </div>
      <h3 className='label1'>TRANSACTIONS</h3>
      <div className="search-container">
        {!inputValue && (
          <FontAwesomeIcon
            icon={faSearch}
            className="search-icon1"
            onClick={clearInput}
          />
        )}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
      <div className='button-container'>
        <button className='button1' onClick={handleTemplate}>
          <FontAwesomeIcon icon={faPlus} className="plus-icon" />
          Add Purchase
        </button>
      </div>
      <div className="transaction-list-header">
        {/* <div>id</div> */}
        <div>DATE</div>
        <div>PARTY NAME</div>
        <div>REFERENCE NO.</div>
        <div>AMOUNT</div>
        <div>PAYMENT TYPE</div>
        <div>ACTIONS</div>
      </div>
      <div className="transaction-list-container">
        {currentItems.length > 0 ? (
          currentItems.map((transaction, index) => (
            <div className="transaction-item" key={index}>
              {/* <div>{transaction.id}</div> */}
              <div>{transaction.created_at.slice(0, 10)}</div>
              <div>{transaction.party_name}</div>
              <div>{transaction.invoice_number}</div>
              <div>{transaction.price}</div>
              <div>{transaction.payment_type}</div>
              <div className="button-container1">
                <button className="delete-button" onClick={() => handleDelete(transaction.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="no-transactions">{error}</div>
        )}
      </div>
      <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className='page-item'>
              <button onClick={() => paginate(number)} className='page-link'>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>

    </div>
  );
};

export default TransactionList;
