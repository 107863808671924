import React, { useEffect, useState } from "react";
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import "./CreateCustomer.css";
import { validateEmail, validatePhone } from "../Url/validationUtils";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../ApiRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Copyrights from "./Copyrights";
import { useTranslation } from "react-i18next";
import successNotification from "../Notification/SuccessNotification";


const StaffCollapse = () => {
  const [staffData, setStaffData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    temporary_address: "",
    permanent_address: "",
    gender: "",
    id_proof: null,
    shift_id: "",
    monthly_salary: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState([]);
  const { t } = useTranslation();



  useEffect(() => {
    getRoleData();
  }, [])

  const getRoleData = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/manage_role/roles`)
    if (response.status === 200) {
      setRoleData(response.data.data);
    }
  }

  const handleStaffChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const indianPhoneRegex = /^$|^[6-9]\d{0,9}$/;
  
      if (indianPhoneRegex.test(value)) {
        setStaffData({ ...staffData, [name]: value });
      }
    }
    else if (name === "monthly_salary") {
      if (/^\d*\.?\d*$/.test(value)) {
        setStaffData({ ...staffData, [name]: value });
      }
    } else {
      setStaffData({ ...staffData, [name]: value });
    }
  };

  const handleImageChange = (e) => {
    setStaffData({ ...staffData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const form = e.target;
    const formData = new FormData(form);

    formData.append("user[first_name]", staffData.first_name);
    formData.append("user[last_name]", staffData.last_name);
    formData.append("user[email]", staffData.email);
    formData.append("user[phone]", staffData.phone);
    formData.append("user[temporary_address]", staffData.temporary_address);
    formData.append("user[permanent_address]", staffData.permanent_address);
    formData.append("user[gender]", staffData.gender);
    formData.append("user_role[role_id]", staffData.role);
    formData.append(
      "user[monthly_salary]",
      parseFloat(staffData.monthly_salary)
    );
    if (staffData.id_proof instanceof File) {
      formData.append("user[id_proof]", staffData.id_proof);
    }

    const errors = [];

    // Email validation check
    if (!staffData.email) {
      errors.push("Email is required.");
    } else {
      const emailValidationResult = validateEmail(staffData.email);
      if (emailValidationResult) {
        errors.push(emailValidationResult);
      }
    }

    if (!staffData.phone) {
      errors.push("Phone number is required.");
    } else {
      const phoneValidationResult = validatePhone(staffData.phone);
      if (phoneValidationResult) {
        errors.push(phoneValidationResult);
      }
    }

    if (!staffData.gender) {
      errors.push("Gender is required.");
    }

    if (!staffData.role) {
      errors.push("Role is required.");
    }

    if (errors.length > 0) {
      errors.forEach((errorMessage) => {
        ErrorNotification(errorMessage);
      });
      return;
    }
    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/staff_management/staffs`,
        formData
      )
      if (response.status === 200) {
        successNotification("Staff created successfully");
        navigate("/staff");
      }
    } catch (error) {
      error.data.errors.map(error=>ErrorNotification(error))
    }
  };

  const handleCancel = () => {
    navigate("/staff");
  };



  return (
    <>
      <div className="content">
        <div className="col-md-12 margin-btm">
          <h3 className="title">{t("Add Staff")}</h3>
        </div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("First Name")}
                  name="first_name"
                  value={staffData.first_name}
                  onChange={handleStaffChange}
                  maxLength={30}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Last Name")}
                  name="last_name"
                  value={staffData.last_name}
                  onChange={handleStaffChange}
                  maxLength={30}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${submitted && !staffData.email && "required-field"
                    }`}
                  placeholder={t("Email*")}
                  name="email"
                  value={staffData.email}
                  onChange={handleStaffChange}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${submitted && !staffData.phone && "required-field"
                    }`}
                  placeholder={t("Phone No*")}
                  name="phone"
                  value={staffData.phone}
                  onChange={handleStaffChange}
                />
              </div>
              <div className="col-md-6 position-relative">
                <select
                  name="gender"
                  className={`form-control select-input ${submitted && !staffData.gender ? "required-field" : ""
                    }`}
                  placeholder={t("Gender*")}
                  value={staffData.gender}
                  onChange={(e) => setStaffData({ ...staffData, gender: e.target.value })}
                >
                  <option value="">{t("Select Gender*")}</option>
                  <option value="male">{t("Male")}</option>
                  <option value="female">{t("Female")}</option>
                  <option value="others">{t("Others")}</option>
                </select>
                <span className="staff-dropdown-icon">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </div>

              <div className="col-md-6 position-relative">
                <select
                  name="role"
                  className={`form-control select-input ${submitted && !staffData.role ? "required-field" : ""}`}
                  value={staffData.role}
                  onChange={(e) => setStaffData({ ...staffData, role: e.target.value })}
                >
                  <option value="">{t("Select Role*")}</option>
                  {roleData.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                <span className="staff-dropdown-icon">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Temporary Address")}
                  name="temporary_address"
                  value={staffData.temporary_address}
                  onChange={handleStaffChange}
                  maxLength="64"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Permanent Address")}
                  name="permanent_address"
                  value={staffData.permanent_address}
                  onChange={handleStaffChange}
                  maxLength="64"
                />
              </div>

              <div className="col-md-6">
                <input
                  type="file"
                  className="form-control upload-img"
                  placeholder={t("Upload Id Proof")}
                  name="id_proof"
                  onChange={handleImageChange}
                />
                <label className="note">{t("Upload Id-Proof")}</label>
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter Monthly Salary")}
                  name="monthly_salary"
                  value={staffData.monthly_salary}
                  onChange={handleStaffChange}
                  maxLength="10"
                  pattern="\d{1,10}"
                  inputMode="numeric"
                />
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">
                  {t("Save")}
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleCancel}
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default StaffCollapse;
