import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCog } from "@fortawesome/free-solid-svg-icons";
import "./item.css";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../Url/constants";
import Copyrights from "../Copyrights";
import Search from "../Search/Search.jsx";
import { NotificationContainer } from "react-notifications";
import CustomTable from "../Common/CustomTable.jsx";
import PaginationControls from "../Common/PaginationControls.jsx";
import EntriesPerPageDropdown from "../Common/EntriesPerPageDropdown.jsx";
import { BsEye, BsPencilSquare, BsPrinter } from "react-icons/bs";
import QuantitEdit from "./ItemPopup/QuantityEdit.jsx";
import ItemAdditionalSetting from "./ItemPopup/ItemAdditionalSetting.jsx";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../Notification/ErrorNotification.jsx";
import { sendRequest } from "../../ApiRequest.js";
import BarcodePrintOption from "./BarcodePrint/BarcodePrintOption.jsx";

function ItemTable() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isQtyEditPopupOpen, setIsQtyEditPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
  const [selectedSettingItem, setSelectedSettingItem] = useState(null);
  const [globalSettings, setGlobalSettings] = useState([]);
  const [barcodePopupOpen, setBarcodePopupOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState("en");
  const { t } = useTranslation();

  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);

  useEffect(() => {
    fetchItems(currentPage);
    fetchSettings();
  }, [recordPerPage, searchQuery, currentPage]);

  useEffect(() => {
    const language = localStorage.getItem("selectedLanguage") || "en";
    setUserLanguage(language);
  }, []);

  const fetchItems = async (pageNumber) => {
    try {
      let request_url = `${BASE_URL}/v1/inventory_managment/items?&page=${pageNumber}&per_page=${recordPerPage}`;
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }
      const response = await sendRequest('GET', request_url)
      const data = await response.data;
      setItems(data.data);
      setTotalPages(Math.ceil(data.total_count / recordPerPage));
      setTotalRecords(data.total_count);
    } catch (error) {
      ErrorNotification('Error fetching items:', error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/billing_settings`
      );
      setGlobalSettings(response.data.data);
    } catch (error) {
      ErrorNotification("Error fetching settings:", error);
    }
  };

  const isCheckboxEnabled = (optionKey) => {
    const option = globalSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const discountEnabled = isCheckboxEnabled("discount");
  const globalTaxEnabled = isCheckboxEnabled("item_wise_tax");

  const handleSearch = async (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const openImagePopup = (image) => {
    setSelectedImage(image);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  const openQtyEditPopup = (item) => {
    setSelectedItem(item);
    setIsQtyEditPopupOpen(true);
  };

  const closeQtyEditPopup = () => {
    setIsQtyEditPopupOpen(false);
    setSelectedItem(null);
  };

  const openSettingPopup = (item) => {
    setSelectedSettingItem(item);
    setIsSettingPopupOpen(true);
  };

  const closeSettingPopup = () => {
    setIsSettingPopupOpen(false);
    setSelectedSettingItem(null);
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getShortTaxType = (taxType) => {
    const taxTypeMap = {
      fixed: "fix",
      percentage: "per",
    };
    return taxTypeMap[taxType] || taxType;
  };

  const handlePrintBarcodes = (quantity) => {
    if (quantity > selectedItem.quantity) {
      ErrorNotification(`barcode Quantity should not greater than ${selectedItem.quantity}`);
      return;
    }
    const printWindow = window.open("");

    printWindow.document.write(`
      <html>
        <head>
          <title>Print Barcodes</title>
          <style>
            @media print {
              body * {
                visibility: hidden;
              }
              #printable-bill, #printable-bill * {
                visibility: visible;
              }
              #printable-bill {
                position: absolute;
                left: 8mm;
                top: 5;
                right: 1mm;
                width: calc(100% - 2mm);
              }
            }
            .sticker {
              width: 60mm;
              height: 30mm;
              border: 1px solid #000;
              page-break-inside: avoid;
              padding: 1mm;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 1.5mm;
              margin-right: 1.5mm;
              margin-bottom: 1mm;
              margin-left: -8mm;
            }

            .left-section, .right-section {
              width: 100%;
              text-align: center;
            }

            .left-section {
              display: flex;
              justify-content: space-between;
            }

            .barcode-image img {
              width: 100%;
              height: 7mm;
              margin-top: 1mm;
              margin-bottom: 1mm;
            }

            .barcode-number, .price-font-size {
              font-size: 16px;
              max-width: 100%;
              text-align: center;
            }

            .barcode-number {
              margin-bottom: 2mm;
              font-weight: bold;
            }

            .price-font-size {
              display: flex;
              flex-flow: column;
            }

            .item-sales-price-value {
              font-weight: bold;
            }

            .item-sale-price-symbol{
              width: 5mm;
            }
          </style>
        </head>
        <body>
          <div id="printable-bill">
            ${Array(quantity).fill('').map(() => `
              <div class="sticker">
                <div class="left-section">
                  <div class="price-font-size">
                    <span class="item-sale-price-symbol">MRP</span>
                    <span class="item-sales-price-value">${selectedItem?.sales_price ? `${selectedItem.sales_price} ₹` : ""}</span>
                  </div>
                  <div> ${selectedItem?.cat_name ? selectedItem?.cat_name : ""}</div>
                </div>
                <div class="right-section barcode-image">
                  <img src="${BASE_URL}${selectedItem?.bar_code_url}" alt="Barcode" />
                  <div class="barcode-number">${selectedItem?.bar_code}</div>
                </div>
              </div>
            `).join('')}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    };
  };


  const openBarcodePrint = (item) => {
    setSelectedItem(item);
    setBarcodePopupOpen(true);
  }

  const handleCloseBarcodePopup = () => {
    setBarcodePopupOpen(false);
  }
  return (
    <>
      <div className="item-table-content">
        <div className="button-container">
          <Link to="/item" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} className="plus-icon" />
            <span>{t("Add Item")}</span>
          </Link>
        </div>
        <div className="row mt-4">
          <div className="col-md-9 d-flex justify-content-start"><h3 className="heading-title">{t("All Items")} ({totalRecords})</h3>
          </div>
          <div className="pagination-dropdown">
            <div className="pagination-controls">
              <EntriesPerPageDropdown
                recordPerPage={recordPerPage}
                handleRecordPerPageChange={handleRecordPerPageChange}
              />
            </div>
            <div
              className="text-right search-adjust"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            >
              <Search placeholder={"Search Items ..."} />
            </div>
          </div>
        </div>
        <div className="table-view">
          <CustomTable
            col_names={[
              'S. No.',
              'Item Name',
              'Item Code',
              'Sales Price',
              'Purchase Price',
              ...(discountEnabled ? ['Sales Discount'] : []),
              'Quantity',
              ...(globalTaxEnabled ? ['Tax'] : []),
              ...(globalTaxEnabled ? ['Tax Type'] : []),
              ...(discountEnabled ? ['Discount Price'] : []),
              'Unit',
              ...(globalTaxEnabled ? ['Tax Inclusive'] : []),
              'Category',
              'Item Image',
              'Actions'
            ]}
            data={items.map((item, index) => {
              let translatedName;
              try {
                translatedName = JSON.parse(item.translated_name);
              } catch {
                translatedName = {};
              }
              const itemData = {
                "S. No.": startIndex + index,
                "Item Name":
                  userLanguage === "hi" &&
                    item.translated_name &&
                    translatedName.hi
                    ? translatedName.hi
                    : item.item_name,
                "Item Code": item.item_code,
                "Sales Price": `₹${item.sales_price}`,
                "Purchase Price": `₹${item.purchase_price}`,
                Quantity: (
                  <div>
                    <span className="qty-item-value">{item.quantity}</span>
                    {item.quantity !== -1 && (
                      <span className="Qty-edit-icon">
                        <BsPencilSquare onClick={() => openQtyEditPopup(item)} />
                      </span>
                    )}
                  </div>
                ),
                "Discount Type": item.discount_type,
                "Wholesale Price": item.wholesale_price,
                Unit: item.unit_name,
                "Tax Inclusive": (
                  <span
                    className={
                      item.is_tax_inclusive ? "check-symbol" : "cross-symbol"
                    }
                  >
                    {item.is_tax_inclusive ? "✓" : "x"}
                  </span>
                ),
                'Category': item.cat_name,
                'Item Image': item.item_url ? <Link onClick={() => openImagePopup(item.item_url)}>{t("View Image")}</Link> : null,
                'Actions': (
                  <div className="icons">
                    <FontAwesomeIcon
                      icon={faCog}
                      className="setting-icon"
                      onClick={() => openSettingPopup(item)}
                      title="Settings"
                    />
                    <Link to={`/item-details/${item.id}`} title="View Details">
                      <BsEye className="item-eye-icon" />
                    </Link>
                    <Link to={`/item-update/${item.id}`} title="Edit Item">
                      <BsPencilSquare className="edit-item-attributes" />
                    </Link>
                    <BsPrinter className="barcode-print-icon" onClick={() => openBarcodePrint(item)} title="Print Barcode" />
                  </div>
                ),
              }

              if (discountEnabled) {
                itemData["Sales Discount"] = item.sales_discount;
                itemData["Discount Price"] = item.discount_price;
              }

              if (globalTaxEnabled) {
                itemData["Tax"] = (
                  <div>
                    {item.tax_amount}
                    {item.tax_amount !== 0 && item.tax_type !== "Group" && (
                      <span className="tax-item">
                        ({getShortTaxType(item?.tax_info?.tax_type)})
                      </span>
                    )}
                  </div>
                );
                itemData["Tax Type"] = item.tax_type;
              }

              return itemData;
            })}
          />
          {items.length === 0 && searchQuery.length > 1 && (
            <div className="no-report-data-message">{t("No match found")}</div>
          )}
          {items.length === 0 && searchQuery.length === 0 && (
            <div className="no-report-data-message">{t("No records found")}</div>
          )}
        </div>

        {totalRecords >= recordPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedImage && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="close-sign">
              <span className="image-close" onClick={closeImagePopup}>
                &times;
              </span>
            </div>
            <div>
              <img src={selectedImage} alt="Item" className="modal-image" />
            </div>
          </div>
        </div>
      )}

      {isQtyEditPopupOpen && (
        <div className="popup-overlay">
          <QuantitEdit
            className="add-close"
            item={selectedItem}
            onClose={closeQtyEditPopup}
            setItems={setItems}
          />
        </div>
      )}

      {barcodePopupOpen && (
        <div className="popup-overlay">
          <BarcodePrintOption
            onClose={handleCloseBarcodePopup}
            selectedQuantity={handlePrintBarcodes}
          />
        </div>
      )}

      {isSettingPopupOpen && (
        <div className="item-add-popup">
          <div className="add-setting-close-sign">
            <ItemAdditionalSetting
              item={selectedSettingItem}
              closeSettingPopup={closeSettingPopup}
              type={"individual"}
            />
            <span className="add-close" onClick={closeSettingPopup}>
              &times;
            </span>
          </div>
        </div>
      )}
      <Copyrights />
      <NotificationContainer />
    </>
  );
}

export default ItemTable;
