import { useEffect, useState } from "react";
import { sendRequest } from "../../../../ApiRequest";
import { BASE_URL } from "../../../../Url/constants";
import ErrorNotification from "../../../../Notification/ErrorNotification";
import { Link, useParams, useNavigate } from "react-router-dom";
import CustomTable from "../../../Common/CustomTable";
import { PurchasePayment } from "../../purchase-payment/purchase-payment";
import "./transaction-details.css";
import { useTranslation } from "react-i18next";

export function TransactionDetails() {
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function showPurchaseDetails() {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/billings/bills/${id}`
      );
      const purchaseData = response.data.data;
      setTransactionDetails(purchaseData);
    } catch (error) {
      if (error.data && Array.isArray(error.data.errors)) {
        error.data.errors.forEach((err) => ErrorNotification(err));
      } else {
        ErrorNotification("An unexpected error occurred.");
      }
    }
  }

  useEffect(() => {
    if (id) {
      showPurchaseDetails();
    }
  }, [id]);

  const totalQuantity =
    transactionDetails?.bill_items.reduce(
      (acc, item) => acc + item.quantity,
      0
    ) || 0;
  const totalTax =
    transactionDetails?.bill_items.reduce(
      (acc, item) => acc + item.item_total_tax,
      0
    ) || 0;
  const grossTotal =
    transactionDetails?.bill_items.reduce(
      (acc, item) => acc + item.item_total_amount,
      0
    ) || 0;

  function handleClose() {
    navigate("/purchase", { state: { tab: "transactions" } });
  }

  const col_names = [
    t("Bill Number"),
    t("Supplier Details"),
    t("Total Amount"),
    t("Payment Mode"),
    t("Status"),
    t("Type"),
    t("Total Tax"),
    t("Company"),
    t("Bill To"),
  ];

  const tableData = transactionDetails
    ? [
        {
          "Bill Number": transactionDetails.bill_number,
          "Supplier Details": transactionDetails.ship_from
            ? `${transactionDetails.ship_from.first_name || ""} ${
                transactionDetails.ship_from.last_name || ""
              }`.trim() ||
              `${transactionDetails.ship_from.email || ""} ${
                transactionDetails.ship_from.phone_number || ""
              }`.trim()
            : "",
          "Total Amount": transactionDetails.total_amount,
          "Payment Mode": transactionDetails.payment_mode,
          Status: transactionDetails.status,
          Type: transactionDetails.type,
          "Total Tax": transactionDetails.total_tax,
          Company: transactionDetails.company.name,
          "Bill To": transactionDetails.bill_to
            ? transactionDetails.bill_to.name
            : "N/A",
        },
      ]
    : [];

  const billItemsColNames = [
    t("Item Name"),
    t("Quantity"),
    t("Price"),
    t("Tax"),
    t("Total Amount"),
  ];

  const billItemsData = transactionDetails
    ? transactionDetails.bill_items.map((item) => ({
        "Item Name": item.name,
        Quantity: item.quantity,
        Price: item.sales_price,
        Tax: item.item_total_tax,
        "Total Amount": item.item_total_amount,
      }))
    : [];

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={handleClose}>
          X
        </button>
        <div className="transaction-details-wrapper p-3">
          <h4>{t("Transaction Details")}</h4>
          {transactionDetails ? (
            <div>
              <div className="company-details">
                <img src={transactionDetails.company.logo} alt="Company Logo" />
                <div>
                  <h5>{transactionDetails.company.name}</h5>
                </div>
              </div>
              <CustomTable col_names={col_names} data={tableData} />
              {transactionDetails.bill_items &&
                transactionDetails.bill_items.length > 0 && (
                  <>
                    <h5>{t("Items")}</h5>
                    <CustomTable
                      col_names={billItemsColNames}
                      data={billItemsData}
                    />
                  </>
                )}
              <div>
                <strong>{t("Total Quantity")}: </strong>
                {totalQuantity} <br />
                <strong>{t("Total Tax")}: </strong>
                {totalTax} <br />
                <strong>{t("Gross Total")}: </strong>
                {grossTotal}
              </div>
              <div className="payment-actions">
                <Link
                  to="#"
                  onClick={() => setShowPayment(true)}
                  className="btn btn-primary"
                >
                  {t("Payment IN")}
                </Link>
              </div>
              {showPayment && (
                <div className="payment-section">
                  <PurchasePayment transactionDetails={transactionDetails} />
                </div>
              )}
            </div>
          ) : (
            <p>{t("Loading...")}</p>
          )}
        </div>
      </div>
    </div>
  );
}
