import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import Copyrights from "../../Copyrights";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import "bootstrap/dist/css/bootstrap.min.css";
import "./item-details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import ItemAdditionalSetting from "../ItemPopup/ItemAdditionalSetting";

function ItemDetails() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { t } = useTranslation();
  const [selectedSettingStcok, setSelectedSettingStock] = useState("");
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);

  // const [setting, setSetting] = useState({});

  useEffect(() => {
    fetchItem();
    // getSettings();
  }, []);

  const fetchItem = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items/${id}`
      );
      setItem(response.data.data);
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };

  // const getSettings = async () => {
  //   try {
  //     const response = await sendRequest(
  //       "GET",
  //       `${BASE_URL}/v1/setting/settings/item_setting_options?type=global`,
  //       null
  //     );

  //     const barCodeSetting = response.data.data.find(
  //       (option) => option.name === "bar_code_auto_generate"
  //     );
  //     setSetting((prev) => ({
  //       ...prev,
  //       barCodeAutoGenerate: barCodeSetting.item_setting_config?.status || false,
  //     }));
  //   } catch (error) {
  //     ErrorNotification("Error fetching options config:", error.response || error.message);
  //   }
  // };
  const openSettingPopup = (stock) => {
    setSelectedSettingStock(stock);
    setIsSettingPopupOpen(true);
  };

  const closeSettingPopup = () => {
    setIsSettingPopupOpen(false);
    setSelectedSettingStock(null);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            {item ? (
              <div className="card p-8">
                <div className="card-body">
                  <h5 className="item_label">{t("Item Details")}</h5>
                  <table>
                    <tbody>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Item Name")}
                        </th>
                        <td>{item.item_name}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Item Code")}
                        </th>
                        <td>{item.item_code}</td>
                      </tr>
                      {item.bar_code_url && (
                        <tr>
                          <th className="bg-light text-black">
                            {t("Bar Code")}
                          </th>
                          <td>
                            <img
                              src={`${BASE_URL}${item?.bar_code_url}`}
                              alt="Bar Code"
                              className="img-fluid"
                            />
                            <div className="item-bar-code-value">
                              {item.bar_code}
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th className="bg-light text-black">{t("Hsn No")}</th>
                        <td>{item.hsn}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Category")}</th>
                        <td>{item.cat_name}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Unit")}</th>
                        <td>{item.unit_name}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Quantity")}</th>
                        <td>{item.quantity}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Purchase Price")}
                        </th>
                        <td>₹{item.purchase_price || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Wholesale Price")}
                        </th>
                        <td>₹{item.wholesale_price || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Price")}
                        </th>
                        <td>₹{item.sales_price || 0.0}</td>
                      </tr>
                      {/* <tr>
                        <th className="bg-light text-black">
                          {t("Discount Type")}
                        </th>
                        <td>{item.discount_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Discount")}
                        </th>
                        <td>₹{item.sales_discount || 0.00}</td>
                      </tr> */}
                      <tr>
                        <th className="bg-light text-black">
                          {t("Discount Type")}
                        </th>
                        <td>{item.discount_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Discount")}
                        </th>
                        <td>
                          {item.discount_type === "Percentage"
                            ? `${item.sales_discount || 0.0}%`
                            : `₹${item.sales_discount || 0.0}`}
                        </td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Discount Price")}
                        </th>
                        <td>₹{item.discount_price || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Tax")}</th>
                        <td>
                          {item.tax_amount} ({item?.tax_info?.tax_type})
                        </td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Tax Type")}</th>
                        <td>{item.tax_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Tax Inclusive")}
                        </th>
                        <td>{item.is_tax_inclusive ? "✓" : "x"}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Amount")}
                        </th>
                        <td>₹{item.sale_amount || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Discount Amount")}
                        </th>
                        <td>₹{item.discount_amount || 0.0}</td>
                      </tr>
                      {item.item_url && (
                        <tr>
                          <th className="bg-light text-black">
                            {t("Item Image")}
                          </th>
                          <td>
                            <img
                              src={item.item_url}
                              alt="Item"
                              className="img-fluid"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="loading">{t("Loading...")}</div>
            )}
          </div>

          <div className="col mt-4 mt-md-0">
            {item && item.stocks && item.stocks.length > 0 ? (
              <div className="card p-8">
                <div className="card-body">
                  <h5 className="card-title item_label">
                    {t("Stock Details")}
                  </h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>{t("Stock ID")}</th>
                        <th>{t("Purchase Price")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Sales Price")}</th>
                        <th>{t("Expiry Date")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.stocks.map((stock) => (
                        <tr key={stock?.id}>
                          <td>{stock?.id}</td>
                          <td>{stock?.price}</td>
                          <td>{stock?.quantity}</td>
                          <td>{stock?.sale_price}</td>
                          <td>{stock?.expiry_date}</td>
                          <td>
                            <div>
                              <FontAwesomeIcon
                                icon={faCog}
                                className="setting-icon"
                                onClick={() => openSettingPopup(stock)}
                                title="Settings"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="card p-4">
                <div className="card-body">
                  <p className="text-muted">
                    {t("No stock details available.")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        {isSettingPopupOpen && (
        <div className="stock-add-popup">
          <div className="add-stock-setting-close-sign">
            <ItemAdditionalSetting
              item={selectedSettingStcok}
              closeSettingPopup={closeSettingPopup}
              type={"stock_wise"}
            />
            <span className="add-close" onClick={closeSettingPopup}>
              &times;
            </span>
          </div>
        </div>
      )}
        <NotificationContainer />
      </div>
      <Copyrights />
    </>
  );
}

export default ItemDetails;
