import React from 'react'

const CustomTable = ({ col_names, data }) => {

  return (
    <table className="table table-striped">
      <thead className='sticky-header'>
        <tr>
          {col_names.map((a, index) => (
            <th key={index} scope="col">{a}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((r, rowIndex) => (
          <tr key={rowIndex}>
            {col_names.map((c, colIndex) => (
              <td key={colIndex}>
                {c === 'File' && r[c] ? (
                  <a href={r[c]} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={r[c]}
                      alt="Transaction File"
                      style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/100";
                      }}
                    />
                  </a>
                ) : (
                  r[c]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
