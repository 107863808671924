import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { faPrint, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Copyrights from "../components/Copyrights";
import { useLocation, Link } from "react-router-dom";
import "./Report/reports.css";
import MonthListDropdown from "./Report/MonthListDropdown";
import YearListDropdown from "./Report/YearListDropdown";
import { BASE_URL } from "../Url/constants";
import { getToken } from "../components/SecureStorage/Token";
import CustomTable from "../components/Common/CustomTable";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import EntriesPerPageDropdown from "../components/Common/EntriesPerPageDropdown";
import PaginationControls from "../components/Common/PaginationControls";
import Search from "../components/Search/Search";
import { sendRequest } from "../ApiRequest";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";


const DURATION_OPTIONS = {
  YESTERDAY: "yesterday",
  TODAY: "today",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  CUSTOM: "custom",
};

const REPORT_TYPE_OPTIONS = {
  SALES: "sales",
  PURCHASE: "purchase",
  INVENTORY: "inventory",
};

function SaleReport() {
  const location = useLocation();
  const reportType = location.state;

  const fullMonthList = Array.from({ length: 12 }, (_, index) => {
    const monthName = format(new Date(2022, index, 1), "LLLL");
    return { value: index + 1, label: monthName };
  });

  const token = getToken();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(
    DURATION_OPTIONS.TODAY
  );
  const [selectedReportType, setSelectedReportType] = useState(
    reportType === "Purchase" ? "purchase" : "sales"
  );
  const [reportData, setReportData] = useState([]);
  const [exportFormat, setExportFormat] = useState("");
  const [recordPerPage, setrecordPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const { t } = useTranslation();

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  useEffect(() => {
    fetchTotals();
  },[]);

  const fetchTotals = async () => {
    let url = `${BASE_URL}/v1/reports/reports/totals?filter_by=${selectedDuration}`;
    if (
      selectedDuration === DURATION_OPTIONS.CUSTOM &&
      startDate &&
      endDate
    ) {
      url += `&start_date=${startDate}&end_date=${endDate}`;
    }

    if (selectedDuration === DURATION_OPTIONS.MONTHLY && selectedMonth) {
      url += `&month=${selectedMonth}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedYear
    ) {
      url += `&year=${selectedYear}`;
    }
    try {
      const response = await sendRequest('get', url);
        const { total_sales, total_purchases, total_revenue } = response.data;
        setTotalSale(total_sales);
        setTotalPurchase(total_purchases);
        setTotalRevenue(total_revenue);
    } catch (error) {
      console.error("Error fetching totals:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleDurationChange = (event) => {
    const currentDate = new Date();
    const duration = event.target.value;
    setSelectedDuration(duration);
    if (duration === DURATION_OPTIONS.TODAY) {
      const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
      setStartDate(firstDayOfYear);
    } else if (duration === DURATION_OPTIONS.MONTHLY) {
      const monthList = fullMonthList.filter(
        (month) => month.value === currentDate.getMonth() + 1
      );
      setSelectedMonth(monthList[0].label);
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      setStartDate(firstDayOfMonth);
    } else if (duration === DURATION_OPTIONS.YEARLY) {
      const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
      setStartDate(firstDayOfYear);
    } else if (duration === DURATION_OPTIONS.CUSTOM) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = currentDate
        .toLocaleDateString("en-GB", options)
        .split("/")
        .reverse()
        .join("-");
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    }
  };

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
    const selectElement = event.target;
    selectElement.style.color = "black";
    setExportFormat("");
    setSearchQuery("");
  };

  const handleExportFormatChange = async (e) => {
    const format = e.target.value;
    setExportFormat(format);
    if (format === "pdf") {
      await handleExportPDF();
    }
    if (format === "csv") {
      await handleExportCSV();
    }
  };

  const handleExport = async (format) => {
    try {
      let exportUrl = "";
      if (format === "csv") {
        exportUrl = `${BASE_URL}/v1/reports/reports/export_csv`;
      } else if (format === "pdf") {
        exportUrl = `${BASE_URL}/v1/reports/reports/export_pdf`;
      }

      const response = await axios.get(exportUrl, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          report_type: selectedReportType,
          filter_by: selectedDuration,
          header_title: headerTitle(),
          devise_type: 'web'
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: format === "csv" ? "text/csv" : "application/pdf",
      });

      const currDate = new Date().toLocaleDateString("en-CA");
      const options = { hour: "2-digit", minute: "2-digit", hour12: true };
      const currTime = new Date().toLocaleTimeString([], options);
      // const currTime = new Date().toLocaleTimeString();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${selectedReportType} reports ${currDate} ${currTime} .${format}`
      );
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error exporting ${format.toUpperCase()}:`, error);
    }
  };

  const handleExportCSV = () => {
    handleExport("csv");
  };

  const handleExportPDF = () => {
    handleExport("pdf");
  };

  const headerTitle = () => {
    if (
      selectedDuration === DURATION_OPTIONS.MONTHLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.SALES
    ) {
      return `Sale report - ${
        startDate
          ? startDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })
          : ""
      }`;
    } else if (
      selectedDuration === DURATION_OPTIONS.MONTHLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.PURCHASE
    ) {
      return `Purchase report - ${
        startDate
          ? startDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })
          : ""
      }`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.SALES
    ) {
      return `Sale report - ${selectedYear}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.PURCHASE
    ) {
      return `Purchase report -  ${selectedYear}`;
    } else if (selectedDuration === DURATION_OPTIONS.CUSTOM) {
      return `Report - ${
        startDate && endDate
          ? startDate.toLocaleString("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }) +
            " to " +
            endDate.toLocaleString("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : ""
      }`;
    } else {
      return `Daily ${
        selectedReportType === REPORT_TYPE_OPTIONS.PURCHASE
          ? "purchase"
          : "sales"
      } report`;
    }
  };

  const fetchReportData = async (pageNumber = 1) => {
    try {
      let url = `${BASE_URL}/v1/reports/${selectedReportType}?filter_by=${selectedDuration}&page=${pageNumber}&per_page=${recordPerPage}`;
      if (["sales", "purchase"].includes(selectedReportType)) {
        if (searchQuery) {
          url += `&query=${searchQuery}`;
        }
        if (
          selectedDuration === DURATION_OPTIONS.CUSTOM &&
          startDate &&
          endDate
        ) {
          url += `&start_date=${startDate}&end_date=${endDate}`;
        }

        if (selectedDuration === DURATION_OPTIONS.MONTHLY && selectedMonth) {
          url += `&month=${selectedMonth}`;
        } else if (
          selectedDuration === DURATION_OPTIONS.YEARLY &&
          selectedYear
        ) {
          url += `&year=${selectedYear}`;
        }

        const response = await sendRequest("GET", url);

        if (response.status === 200) {
          const { data, total_pages, total_records } = response.data;
          setReportData(data);
          setTotalPages(total_pages);
          setTotalRecords(total_records);
        } else {
          ErrorNotification("Failed to fetch data");
        }
      } else {
        ErrorNotification("Invalid report type");
      }
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  };

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (selectedDuration === DURATION_OPTIONS.TODAY) {
      fetchReportData();
      fetchTotals();
    }
    else if (selectedDuration === DURATION_OPTIONS.YESTERDAY) {
      fetchReportData();
      fetchTotals();
    }
    else if (selectedDuration === DURATION_OPTIONS.MONTHLY || selectedMonth) {
      fetchReportData();
      fetchTotals();
    } else if (selectedDuration === DURATION_OPTIONS.YEARLY || selectedYear) {
      fetchReportData();
      fetchTotals();
    } else if (selectedDuration === DURATION_OPTIONS.CUSTOM) {
      fetchReportData();
      fetchTotals();
    }
  }, [
    selectedDuration,
    selectedMonth,
    selectedYear,
    selectedReportType,
    startDate,
    endDate,
    recordPerPage,
    searchQuery,
  ]);

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchReportData(pageNumber);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const col_names = [
    t("S. No."),
    selectedReportType === "sales" ? t("Customer details") : t("Supplier details"),
    t("Trans. ID"),
    t("Amount"),
    t("Billing date"),
    t("Payment status"),
    t("View invoice"),
  ];

  const tableData = reportData.map((item, index) => ({
    "S. No.": startIndex + index,
    // "Trans. ID": item.bill_number,
    [col_names[2]]: `${item.email || ""} ${
      item.phone || item.phone_number || ""
    }`
      ? `${item.phone || item.phone_number || ""} ${item.email || ""}`
      : " ",
    "Trans. ID": item.bill_number,
    Amount: `₹${parseFloat(item.total_amount).toFixed(2)}`,
    "Billing date": new Date(item.created_at)
      .toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", ""),
    "Payment status": item.status,
    "View invoice": (
      <Link to={`/invoice/${item.id}`} target="_blank">
        {t("View Details")}
      </Link>
    ),
  }));

  return (
    <>
      <div className="content style-tax">
        <div className="sale-purchase-box mt-10 report-box">
          
          <div className="icon-container">
            <h3 className="title">{t("Report")}</h3>

            <div className="icon-content">
              <p className="action-label">{t("Export")} </p>
              <select
                className="form-select"
                value={exportFormat}
                onChange={handleExportFormatChange}
              >
                <option value="" disabled hidden>
                  {t("Select file type")}
                </option>
                <option value="csv">CSV</option>
                <option value="pdf">PDF</option>
              </select>

              <button className="action-button" onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} className="print-icon" />
              </button>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-4 mt-0">
              <div className="sp-blue common-heigth-report-amount">
                <div className="sp-icon">
                  <svg
                    width="42"
                    height="55"
                    viewBox="0 0 42 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_531_811)">
                      <path
                        d="M0.000447751 8.625C0.000447751 6.23965 1.87732 4.3125 4.20045 4.3125H12.6004H14.7004H37.8004C40.1236 4.3125 42.0005 6.23965 42.0005 8.625C42.0005 11.0104 40.1236 12.9375 37.8004 12.9375H30.4242C31.6842 14.8781 32.6161 17.0613 33.1411 19.4062H37.8004C40.1236 19.4062 42.0005 21.3334 42.0005 23.7188C42.0005 26.1041 40.1236 28.0312 37.8004 28.0312H33.1279C31.3955 35.8881 25.0036 41.9391 17.0892 42.9768L36.0417 56.8711C37.9317 58.2592 38.3648 60.9545 37.013 62.8816C35.6611 64.8088 33.0361 65.267 31.1592 63.8789L1.7592 42.3164C0.276073 41.2383 -0.353927 39.2842 0.197323 37.4918C0.748573 35.6994 2.37607 34.5 4.20045 34.5H14.7004C19.0054 34.5 22.7067 31.8451 24.3211 28.0312H4.20045C1.87732 28.0312 0.000447751 26.1041 0.000447751 23.7188C0.000447751 21.3334 1.87732 19.4062 4.20045 19.4062H24.3211C22.7067 15.5924 19.0054 12.9375 14.7004 12.9375H12.6004H4.20045C1.87732 12.9375 0.000447751 11.0104 0.000447751 8.625Z"
                        fill="#474DDC"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_531_811">
                        <rect width="42" height="69" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="sp-content">
                  <span>{t("Total sale")}</span>
                  <h3>{totalSale?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-0">
              <div className="sp-red common-heigth-report-amount">
                <div className="sp-icon">
                  <svg
                    width="42"
                    height="60"
                    viewBox="0 0 42 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_531_815)">
                      <path
                        d="M0.000447751 8.625C0.000447751 6.23965 1.87732 4.3125 4.20045 4.3125H12.6004H14.7004H37.8004C40.1236 4.3125 42.0005 6.23965 42.0005 8.625C42.0005 11.0104 40.1236 12.9375 37.8004 12.9375H30.4242C31.6842 14.8781 32.6161 17.0613 33.1411 19.4062H37.8004C40.1236 19.4062 42.0005 21.3334 42.0005 23.7188C42.0005 26.1041 40.1236 28.0312 37.8004 28.0312H33.1279C31.3955 35.8881 25.0036 41.9391 17.0892 42.9768L36.0417 56.8711C37.9317 58.2592 38.3648 60.9545 37.013 62.8816C35.6611 64.8088 33.0361 65.267 31.1592 63.8789L1.7592 42.3164C0.276073 41.2383 -0.353927 39.2842 0.197323 37.4918C0.748573 35.6994 2.37607 34.5 4.20045 34.5H14.7004C19.0054 34.5 22.7067 31.8451 24.3211 28.0312H4.20045C1.87732 28.0312 0.000447751 26.1041 0.000447751 23.7188C0.000447751 21.3334 1.87732 19.4062 4.20045 19.4062H24.3211C22.7067 15.5924 19.0054 12.9375 14.7004 12.9375H12.6004H4.20045C1.87732 12.9375 0.000447751 11.0104 0.000447751 8.625Z"
                        fill="#FE1818"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_531_815">
                        <rect width="42" height="69" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="sp-content">
                  <span>{t("Total purchase")}</span>
                  <h3>{totalPurchase?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-0">
              <div className="sp-green common-heigth-report-amount">
                <div className="sp-icon">
                  <svg
                    width="50"
                    height="60"
                    viewBox="0 0 50 73"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clipPath="url(#clip0_531_817)"
                      filter="url(#filter0_d_531_817)"
                    >
                      <path
                        d="M4.00045 8.625C4.00045 6.23965 5.87732 4.3125 8.20045 4.3125H16.6004H18.7004H41.8004C44.1236 4.3125 46.0005 6.23965 46.0005 8.625C46.0005 11.0104 44.1236 12.9375 41.8004 12.9375H34.4242C35.6842 14.8781 36.6161 17.0613 37.1411 19.4062H41.8004C44.1236 19.4062 46.0005 21.3334 46.0005 23.7188C46.0005 26.1041 44.1236 28.0312 41.8004 28.0312H37.1279C35.3955 35.8881 29.0036 41.9391 21.0892 42.9768L40.0417 56.8711C41.9317 58.2592 42.3648 60.9545 41.013 62.8816C39.6611 64.8088 37.0361 65.267 35.1592 63.8789L5.7592 42.3164C4.27607 41.2383 3.64607 39.2842 4.19732 37.4918C4.74857 35.6994 6.37607 34.5 8.20045 34.5H18.7004C23.0054 34.5 26.7067 31.8451 28.3211 28.0312H8.20045C5.87732 28.0312 4.00045 26.1041 4.00045 23.7188C4.00045 21.3334 5.87732 19.4062 8.20045 19.4062H28.3211C26.7067 15.5924 23.0054 12.9375 18.7004 12.9375H16.6004H8.20045C5.87732 12.9375 4.00045 11.0104 4.00045 8.625Z"
                        fill="#23A203"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_531_817">
                        <rect
                          width="42"
                          height="69"
                          fill="white"
                          transform="translate(4)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="sp-content">
                  <span>{t("Total revenue")}</span>
                  <h3>{totalRevenue?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-2">
              <select
                className="form-select report-type"
                onChange={handleReportTypeChange}
                value={selectedReportType}
              >
                <option value={REPORT_TYPE_OPTIONS.SALES}>
                  {t("Sale report")}
                </option>
                <option value={REPORT_TYPE_OPTIONS.PURCHASE}>
                  {t("Purchase report")}
                </option>
              </select>
            </div>

            <div className="col-md-2">
              <div className="select-report-content">
                <select
                  className="form-select report-type"
                  onChange={handleDurationChange}
                  defaultValue={DURATION_OPTIONS.TODAY}
                >
                  <option value={DURATION_OPTIONS.TODAY}>{t("Today")}</option>
                  <option value={DURATION_OPTIONS.YESTERDAY}>{t("Yesterday")}</option>
                  <option value={DURATION_OPTIONS.MONTHLY}>
                    {t("Monthly")}
                  </option>
                  <option value={DURATION_OPTIONS.YEARLY}>{t("Yearly")}</option>
                  <option value={DURATION_OPTIONS.CUSTOM}>{t("Custom")}</option>
                </select>
              </div>
            </div>

            {selectedDuration === DURATION_OPTIONS.CUSTOM && (
              <>
                <div className="col-md-2">
                  <div className="date-picker-container">
                    <DatePicker
                      placeholderText="Start date"
                      showYearDropdown
                      showMonthDropdown
                      selected={startDate}
                      dateFormat="d MMM yyyy"
                      onChange={(date) => handleStartDateChange(date)}
                      className="date-picker report-type form-select"
                      maxDate={endDate ? endDate : new Date()}
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="date-picker-icon"
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="date-picker-container">
                    <DatePicker
                      placeholderText="End date"
                      showYearDropdown
                      showMonthDropdown
                      selected={endDate ? endDate : new Date()}
                      dateFormat="d MMM yyyy"
                      onChange={(date) => handleEndDateChange(date)}
                      minDate={startDate}
                      maxDate={new Date()}
                      className="date-picker report-type form-select"
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="date-picker-icon"
                    />
                  </div>
                </div>
              </>
            )}

            {selectedDuration === DURATION_OPTIONS.MONTHLY && (
              <div className="col-md-2">
                <MonthListDropdown
                  selectedMonth={selectedMonth}
                  handleMonthChange={handleMonthChange}
                />
              </div>
            )}

            {selectedDuration === DURATION_OPTIONS.YEARLY && (
              <div className="col-md-2">
                <YearListDropdown
                  selectYear={selectedYear}
                  handleYearChange={handleYearChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="pagination-dropdown">
          <div className="pagination-controls">
            <EntriesPerPageDropdown
              recordPerPage={recordPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="reports-control-label">
            <h3 className="title">{headerTitle()}</h3>
          </div>
          <div
            className="reports-search-box"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          >
            <Search placeholder={t("Search reports...")} />
          </div>
        </div>

        <br />

        <div className="bd-example sp-table sp-table-header">
          <div className="report-table-data">
            <CustomTable col_names={col_names} data={tableData} />
          </div>
        </div>

        {reportData.length === 0 && (
          <div className="no-report-data-message">{t("No records found")}</div>
        )}

        <>
          {reportData.map((index) => (
            <div key={index}></div>
          ))}

          {reportData.length > 0 && (
            <div>
              {totalRecords >= recordPerPage && (
                <div className="report-pagination-info">
                  <div className="report-pagination-container">
                    <PaginationControls
                      totalRecords={totalRecords}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      paginate={paginate}
                      startIndex={startIndex}
                      endIndex={endIndex}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
        <NotificationContainer />
      </div>
      <Copyrights />
    </>
  );
}

export default SaleReport;
